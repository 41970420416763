import type { RangeFilter } from '@api/utils';

const getRangeFilterString = (filters: string[]) => {
	return filters.find((f) => f.includes('starting_price'));
};

const getRangeFilterValues = (filters: string[]) => {
	const rangeFilterString = getRangeFilterString(filters);
	const [, rangeMinStr, rangeMaxStr] = rangeFilterString?.split('-') || [];

	const rangeMin = Number.isNaN(Number(rangeMinStr))
		? null
		: Number(rangeMinStr);
	const rangeMax = Number.isNaN(Number(rangeMaxStr))
		? null
		: Number(rangeMaxStr);

	return {
		rangeMin,
		rangeMax,
	};
};

const createRangeFilterObj = (filters: string[]) => {
	const { rangeMin, rangeMax } = getRangeFilterValues(filters);

	const rangeFilter: RangeFilter = {
		field: 'STARTING_PRICE',
	};

	if (typeof rangeMin === 'number') {
		rangeFilter.gte = rangeMin;
	}

	if (typeof rangeMax === 'number') {
		rangeFilter.lte = rangeMax;
	}

	return rangeFilter;
};

const getRangeFilters = (filters: string[]) => {
	const rangeFilterObj = createRangeFilterObj(filters);
	return rangeFilterObj.gte || rangeFilterObj.lte ? [rangeFilterObj] : [];
};

const getCleanFilters = (filters: string[]) => {
	return filters.filter((f) => !f.includes('starting_price'));
};

export {
	getRangeFilterString,
	getRangeFilterValues,
	createRangeFilterObj,
	getRangeFilters,
	getCleanFilters,
};

import searchCount from '@api/searchCount';
import { receiveFilterPills } from '@redux/search';
import { Logger } from '@utils/logger/logger';
import categoryFilters from '../../../../api/categoryFilters';
import searchFacets from '../../../../helpers/seo/searchFacetSeoText';
import isResponseError from '../../../../utils/isResponseError';
import {
	outdoorFilter,
	settingsFilter,
} from '../../../pages/VendorsSearch/containers/FilterPills/helpers';
import { replaceSettingsFilterOptions } from '../../../pages/VendorsSearch/containers/FilterPills/utils';
import { getCategory } from '../../category';
import { getCleanFilters, getRangeFilters } from '../utils/buildPricingFilters';

const isClient = typeof global === 'undefined';
export function setCategoryFilters(
	updatedCategoryFilters: Search.SearchFilters,
	query: string,
	facetParam: string,
	categoryCode: FilterPills.VendorCategories,
): Redux.ThunkResult<void> {
	return (dispatch, getState) => {
		const state = getState();
		const mobileUI = state.viewport.isMobile;
		const { name, filters } = updatedCategoryFilters;
		const modifiedFilters = replaceSettingsFilterOptions(
			filters,
			settingsFilter,
		);
		const filterList = [...modifiedFilters, outdoorFilter];

		dispatch(
			receiveFilterPills({
				filters,
				mobileUI,
				categoryCode,
			}),
		);
		dispatch({
			type: 'filters/SET_CATEGORY_FILTERS',
			payload: {
				facetParam,
				query,
				filters: {
					name,
					filters: filterList,
				},
			},
		});
	};
}

// These should only be called on the server-side.
export function fetchCategoryFilters(
	query: Search.Query,
	facetParam: string,
	categoryCode: FilterPills.VendorCategories,
): Redux.ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const state = getState();
		const category = getCategory(state);
		const visitorId = state.visitor?.id ? state.visitor.id : '';
		const mobileUI = state.viewport.isMobile;
		const response = await categoryFilters(category.id, visitorId);
		const { data } = response;

		if (isResponseError(data)) {
			if (isClient) {
				const errorReponse =
					(data as API.ErrorResponse<API.CategoryFilterData>) || {};
				const [error] = errorReponse.errors || [];

				Logger.error({
					log_message: error?.message,
					errorToLog: new Error('Error fetching category filters'),
					newRelic: true,
				});
			}

			return;
		}
		const {
			data: {
				filterSummary: { name, filters },
			},
		} = data;
		dispatch(
			receiveFilterPills({
				categoryCode,
				filters,
				mobileUI,
			}),
		);
		dispatch({
			type: 'filters/POPULATE_CATEGORY_FILTERS',
			payload: {
				facetParam,
				query,
				filters: {
					name,
					filters,
				},
			},
		});
	};
}

export function getFollowableFacets(): Redux.ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const state = getState();
		const categoryCode = getCategory(state).code;

		const followableFacets = searchFacets
			.filter((facet) => facet.category_code === categoryCode)
			.map((facet) => facet.filter_id);

		dispatch({
			type: 'filters/UPDATE_FOLLOWABLE_FACETS',
			payload: {
				followableFacets,
			},
		});
	};
}

export function fetchResultsCount(
	filters: string[],
): Redux.ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const {
			location: { city, stateCode },
		} = getState();
		const result = await searchCount({
			city,
			state: stateCode,
			filters: getCleanFilters(filters),
			rangeFilters: getRangeFilters(filters),
		});

		dispatch({
			type: 'filters/SET_STAGED_RESULTS_COUNT',
			payload: result,
		});
	};
}

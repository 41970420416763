import { transformVendorTier } from '@utils/transformVendorTier';
import type { ImpressionType } from 'types/vendorImpressions';
import * as actionCreators from './actionCreators';
import report from './reportError';
import {
	getAppliedFiltersForBucketedImpressions,
	getBucketedImpressions,
	getDirectorySortScoreId,
	getListingDetails,
	isOnDirectoryPage,
} from './utils';

const DEFAULT_TRANSMIT_LIMIT = 12;
export const IMPRESSION_QUEUE_IDLE_TIMEOUT = 60 * 1000; // 1 minute

// Data team would prefer us to send in batches of 12,
// but occasionally we need to flush the queue when there are fewer
// such as (when idle)
type TransmitMinimumLimit = 1 | typeof DEFAULT_TRANSMIT_LIMIT;

export const transmitImpressions =
	(
		transmitMinimumLimit: TransmitMinimumLimit = DEFAULT_TRANSMIT_LIMIT,
		cardUiLocation?: string,
	): Redux.ThunkResult<void> =>
	async (dispatch, getState) => {
		const reduxState = getState();
		const { impressionQueue: queue } = reduxState.vendorImpressions;
		const sentImpressions: VendorImpressions.Impression[] = [];
		while (queue.length >= transmitMinimumLimit) {
			const impressions = queue.splice(0, DEFAULT_TRANSMIT_LIMIT);

			if (window?.analytics) {
				const { code: categoryCode } = reduxState.category;
				const { marketCode, city, stateCode } = reduxState.location;

				const impressionBuckets = getBucketedImpressions(
					impressions,
					reduxState,
				);

				const appliedFilters =
					getAppliedFiltersForBucketedImpressions(reduxState);

				Object.keys(impressionBuckets).forEach(
					(impressionType: ImpressionType) => {
						window.analytics.track('Vendor Tile Viewed', {
							product_listing_type: impressionType,
							category_id: categoryCode,
							region_id: marketCode,
							display_location_name: `${city}, ${stateCode}`,
							sort_type: isOnDirectoryPage(reduxState)
								? reduxState.search.sort
								: null,
							directory_filters: appliedFilters,
							page_number: reduxState.search.pagination.page,
							vendor_impression_list: impressionBuckets[impressionType],
						});
					},
				);
			}

			sentImpressions.push(...impressions);
		}

		dispatch(actionCreators.clearTransmittedVendors(sentImpressions));
	};

export const setIdleTimer = (
	cardUiLocation?: string,
): Redux.ThunkResult<void> => {
	return async (dispatch, getState) => {
		const { currentTimeout } = getState().vendorImpressions;
		clearTimeout(currentTimeout);
		const timeout = window.setTimeout(() => {
			dispatch(transmitImpressions(1, cardUiLocation));
			dispatch(actionCreators.setCurrentTimeout(undefined)); // reset current timeout
		}, IMPRESSION_QUEUE_IDLE_TIMEOUT);

		dispatch(actionCreators.setCurrentTimeout(timeout));
	};
};

export const queueVendorImpression =
	(
		details: VendorImpressions.ImpressionDetails,
	): Redux.ThunkResult<Promise<void>> =>
	async (dispatch, getState) => {
		const { locationIndex, impressionType, vendor, cardUiLocation } = details;
		const reduxState = getState();

		const position =
			locationIndex > 0
				? locationIndex
				: report.position(locationIndex, details);

		const { currentTimeout, directoryStyle, impressionQueue } =
			getState().vendorImpressions;

		const { impression_style: impressionStyle } = JSON.parse(
			getListingDetails(reduxState, cardUiLocation),
		); // TODO: SafeGuard

		if (!directoryStyle) {
			dispatch(actionCreators.setDirectoryStyle(impressionStyle));
		}

		// Clear the queue if we switch from image to map (or vice versa) on directory pages
		if (directoryStyle && directoryStyle !== impressionStyle) {
			clearTimeout(currentTimeout);
			dispatch(transmitImpressions(1, directoryStyle));
			dispatch(actionCreators.setDirectoryStyle(impressionStyle));
		}

		const impression: VendorImpressions.Impression = {
			ai_photo_id: vendor.ai?.photo?.id || null,
			directory_sort_score_id: getDirectorySortScoreId(reduxState, vendor),
			id: vendor.id,
			list: impressionType,
			name: vendor.name,
			position,
			storefront_card_id: vendor.storefrontCard?.id || null,
			variant: transformVendorTier(vendor.vendorTier),
			storefront_completion_status: vendor.statistics?.completionScore || null,
			impression_timestamp: new Date().toISOString(),
		};
		dispatch(actionCreators.queueVendorImpression(impression));
		// We need to send state page impressions one at a time.
		const isStatePage = reduxState.page.pageType === 'state';
		const canTransmit =
			[...impressionQueue, impression].length >= DEFAULT_TRANSMIT_LIMIT ||
			isStatePage;

		if (canTransmit) {
			clearTimeout(currentTimeout);
			const transmitMinimumLimit = isStatePage ? 1 : DEFAULT_TRANSMIT_LIMIT;
			dispatch(transmitImpressions(transmitMinimumLimit, directoryStyle));
		} else {
			dispatch(setIdleTimer(cardUiLocation));
		}
	};
